.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--header-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.25);
  background-color: var(--primary-light);
  z-index: 100;

  &__logo {
    height: 100%;

    &-img {
      height: 100%;
    }
  }
  &__admin-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    position: absolute;
    z-index: 1500;
    top: 20px;
    right: 20px;
  }
  &__button {
    color: var(--primary-light);
    font-size: 1.8rem;
    cursor: pointer;
    font-family: inherit;
    padding: 1.5rem;
    background-color: var(--primary-pink);
    border: none;
    border-radius: 15px;

    &:hover,
    &:active {
      transform: scale(0.98);
    }
  }
}
