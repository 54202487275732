.hero,
.info,
.cta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: var(--content-height);
  gap: 3rem;
}
/* PARALLAX BACKGROUNDS*/
.background-one,
.background-two {
  background-image: url('../assets/logo/snura-transparent.webp'),
    linear-gradient(#fff, #fff);

  height: 30vh;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  @supports not (-webkit-touch-callout: none) {
    background-attachment: fixed;
    background-position: center;
  }

  @include device(tablet-port) {
    height: var(--content-height);
  }
}

.background-two {
  background-image: url('../assets/logo/puncosky-mimi.webp'),
    linear-gradient(#fff, #fff);
  background-size: cover;
}

/* HERO SECTIONS */
.hero {
  background-image: url('../assets/background/pink-circles.svg'),
    linear-gradient(#fff, #fff);
  margin-top: var(--header-height);
  &__title {
    @include flex-center-both;
    flex-direction: column;
    font-size: 4rem;
    text-align: center;
  }
  &__loana {
    background-image: url('../assets/logo/loana-logo.webp');
    width: 30rem;
    height: 15rem;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__paragraph {
    font-size: 3rem;
    text-align: center;
    padding: 0 5rem;

    span {
      color: var(--primary-green);
      font-weight: 700;
    }
  }
}

/* INFO SECTION*/
.info {
  background-image: url('../assets/background/green-circles.svg'),
    linear-gradient(#fff, #fff);
  height: calc(100vh - var(--navbar-height));
  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5rem;

    &-paragraph {
      font-size: 2.8rem;
      padding: 0 3.5rem;

      span:nth-of-type(1) {
        @extend .highlight-pink;
      }

      span:nth-of-type(2) {
        @extend .highlight-purple;
      }

      span:nth-of-type(3) {
        @extend .highlight-orange;
      }
      span:nth-of-type(4) {
        @extend .highlight-green;
      }
      span:nth-of-type(5) {
        @extend .highlight-blue;
      }
    }
    @include device(desktop) {
      max-width: 45%;
    }
  }

  &__image {
    @extend .polaroid;
    display: none;

    @include device(desktop) {
      display: flex;
      width: 300px;
      height: 360px;

      transform: rotateZ(15deg) translate(-50px, 10px);
    }
    img {
      width: 90%;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
  }

  @include device(desktop) {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
}

/* CTA SECTION*/
.cta {
  background-image: url('../assets/background/pink-circles.svg'),
    linear-gradient(#fff, #fff);
  height: calc(100vh - var(--navbar-height));
  margin-bottom: var(--navbar-height);
  gap: 3rem;

  &__heading {
    font-size: 4rem;
  }

  &__paragraph {
    font-size: 2.8rem;
    padding: 0 5rem;

    &:nth-of-type(2) {
      text-align: center;
    }

    span:nth-of-type(1) {
      @extend .highlight-purple;
    }

    span:nth-of-type(2) {
      @extend .highlight-orange;
    }
    span:nth-of-type(3) {
      @extend .highlight-green;
    }
  }

  &__button {
    background: none;
    border: none;
    background-color: var(--primary-green);
    padding: 2rem 5rem;
    font-size: 3rem;
    color: var(--primary-light);
    border-radius: 25px;
    cursor: pointer;
    &:hover,
    &:active {
      background-color: var(--primary-pink);
      transform: scale(0.98);
      box-shadow: 2px 2px 3px var(--primary-pink);
    }
  }

  &__link {
    font-weight: 700;
    color: var(--primary-green);
    text-decoration: underline dotted;

    &:hover,
    &:active {
      color: var(--primary-pink);
      text-decoration: underline;
    }
  }
}
