.productItem {
  position: relative;
  width: 20rem;
  height: 30rem;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px;
  &__name {
    letter-spacing: 0.3rem;
    text-align: center;
    text-transform: lowercase;
  }
  &__size {
    font-size: 1.5rem;
  }
  &__images {
    border: 1px solid rgba($color: #000000, $alpha: 0.2);
    width: 18rem;
    height: 18rem;
  }
}
