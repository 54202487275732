@mixin device($breakpoint) {
  @if $breakpoint == sm-phone {
    @media only screen and (max-width: 25em) {
      //400px
      @content;
    }
  }
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      //600px
      @content;
    }
  }
  @if $breakpoint == tablet-port {
    @media only screen and (min-width: 37.5em) {
      //600px 56.25
      @content;
    }
  }
  @if $breakpoint == tablet-land {
    @media only screen and (min-width: 48em) {
      //768px
      @content;
    }
  }
  @if $breakpoint == desktop {
    @media only screen and (min-width: 64em) {
      //1024px
      @content;
    }
  }
  @if $breakpoint == lg-desktop {
    @media only screen and (min-width: 85em) {
      //1024px
      @content;
    }
  }
}

// Main content margins top and bottom adjust
@mixin content-container-adjust {
  min-height: var(--content-height);
  margin-top: calc(var(--header-height));
  margin-bottom: var(--navbar-height);
}

// FLEXBOX
@mixin flex-center-both {
  display: flex;
  justify-content: center;
  align-items: center;
}
