:root {
  /* FONTS */
  --font-primary: 'Nunito', sans-serif;
  --font-secondary: 'Caveat', cursive;

  /* COLORS */
  --primary-light: #fff;
  --secondary-light: #bebebe;

  --primary-grey: #4c4c4c;

  --primary-pink: #e71b69;
  --secondary-pink: #ab0042;

  --primary-orange: #d45412;
  --primary-purple: #85509b;
  --primary-yellow: #f2c722;
  --primary-blue: #211ed5;
  --primary-green: #2fac62;

  --header-height: 10rem;
  --navbar-height: 10rem;

  --test-color: green;

  --content-height: calc(100vh - var(--header-height) - var(--navbar-height));
}
