.products {
  &__container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 18rem;
    padding-bottom: 14rem;
  }

  &__items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5rem;
  }
  &__loader {
    display: flex;
    justify-content: center;
    align-items: centers;
  }
}
