.category-container {
  @include content-container-adjust;
  padding: 4rem 1rem 8rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  width: 100%;
  @include device(tablet-port) {
    flex-direction: row;
    padding: 0 3rem 0 3rem;
  }
}

.category {
  @extend .polaroid;

  margin: 2rem;

  @include device(desktop) {
    transform: scale(1.5);
  }

  &__modal {
    @include flex-center-both;
    position: absolute;
    top: 50vh;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.1);
    height: 100vh;
    width: 100vw;
    @include device(tablet-land) {
      display: none;
    }
    transition: all 0.2s ease-out;
  }

  &__message {
    @include flex-center-both;
    color: var(--primary-pink);
    font-weight: 700;
    text-align: center;
    flex-direction: column;
    gap: 2rem;
    background-color: var(--primary-light);
    padding: 2rem;
    width: 60vw;
    border-radius: 25px;
    border: 5px solid var(--primary-pink);

    p {
      font-size: 2rem;
    }

    button {
      cursor: pointer;
      font-weight: 700;
      font-size: 2rem;
      padding: 1rem 2rem;
      color: inherit;
      border: 2px solid var(--primary-pink);
      border-radius: 5px;
      background: none;
      &:hover {
        transform: scale(0.99);
      }
    }
  }

  .category-image-container {
    width: 140px;
    height: 140px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .category__image {
    &-socks {
      @extend .category-image-container;
      background-image: url('../assets/images/category-socks.webp');
    }
    &-stockings {
      @extend .category-image-container;
      background-image: url('../assets/images/category-stockings.webp');
    }
    &-adults {
      @extend .category-image-container;
      background-image: url('../assets/images/category-adults.webp');
    }
  }

  &__name {
    font-size: 4rem;
    font-family: var(--font-secondary);
  }

  &__price {
    font-size: 2rem;
    letter-spacing: 0px;
    font-family: var(--font-secondary);
    font-weight: 600;
  }

  transition: all 0.3s ease-in;

  &:hover,
  &:active {
    transform: scale(1.1);
    @include device(desktop) {
      transform: scale(1.7);
    }
  }
}

#category-adults {
  letter-spacing: -2px;
  text-align: center;
}
