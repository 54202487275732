.productDetail {
  @include content-container-adjust;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__container {
    @include flex-center-both;
    flex-direction: column;
    background-color: var(--primary-light);
    padding: 3rem 2rem 3rem 2rem;
    gap: 3rem;
    max-width: 85vw;
    min-height: 50vh;
    border: 1px solid rgba($color: #000000, $alpha: 0.25);
    border-radius: 25px;

    @include device(tablet-land) {
      flex-direction: row;
      max-width: 85vw;
    }
    @include device(desktop) {
      max-width: 60vw;
    }
  }

  &__left {
    width: 100%;
    @include device(tablet-land) {
      width: 49%;
    }
  }
  &__right {
    width: 100%;
    @include device(tablet-land) {
      width: 49%;
    }
  }

  &__heading {
    display: block;
    height: 20%;
    text-align: center;
    text-transform: lowercase;

    &.smallscreen {
      @include device(tablet-land) {
        display: none;
      }
    }
    &.largescreen {
      display: none;
      @include device(tablet-land) {
        display: block;
      }
    }
  }

  &__images {
    width: 99%;
    aspect-ratio: 1/1;
  }

  &__description {
    margin-top: 1.5rem;
    font-size: 2rem;
    position: relative;
    padding-top: 2rem;
    text-align: center;

    &::before {
      content: '';
      background-color: var(--secondary-light);
      position: absolute;
      top: 0;
      left: 0;
      height: 1px;
      width: 100%;
      z-index: 100;
      border-radius: 50px;
    }
  }

  &__materials {
    margin-top: 1.5rem;
    font-size: 1.9rem;
    text-align: center;
  }

  &__additional {
    margin-top: 1.5rem;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 15rem;
    @include device(desktop) {
      align-items: flex-start;
    }
    @include device(lg-desktop) {
      height: 10rem;
    }

    li {
      list-style-type: square;
      width: 40%;
      padding-left: -5px;
    }
  }
}

.return-back-icon {
  @include flex-center-both;
  cursor: pointer;
  position: fixed;
  bottom: 12rem;
  right: 15px;
  flex-direction: column;
  border: none;
  background: none;
  z-index: 2000;

  &__img {
    fill: var(--primary-pink);
    width: 4rem;
    height: 4rem;
  }
  &__span {
    color: var(--primary-pink);
    font-size: 2rem;
  }
}
