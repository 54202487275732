.contact {
  &__container {
    @include content-container-adjust;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
  }

  &__heading {
    text-align: center;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    font-size: 2rem;
    padding: 2rem;
    background-color: var(--primary-light);
    width: 80vw;

    border-radius: 15px;
    background: #ffffff;
    box-shadow: 5px 5px 5px rgba($color: #000000, $alpha: 0.04);
    &-span {
      font-weight: 700;
      font-size: 2.3rem;
      color: var(--primary-green);
    }

    &-icon {
      fill: var(--primary-green);
      width: 2.5rem;
      height: 2.5rem;
      margin-right: auto;
    }

    &a {
      cursor: pointer;
    }

    &:first-of-type {
      & span {
        font-weight: 400;
      }
    }

    @include device(desktop) {
      width: 50vw;
    }
  }
}
