.navbar {
  background-color: var(--primary-light);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--navbar-height);
  color: var(--primary-pink);
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  z-index: 1000;

  &__items {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    align-items: center;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 2rem;
    list-style: none;
    font-weight: 700;
    width: 9rem;
    height: 9rem;
    border-radius: 25px;
    gap: 0.5rem;

    &-icon {
      fill: currentColor;
      height: 2.5rem;
    }

    &.on-this-page {
      color: var(--primary-light);
      background-color: var(--primary-pink);
      border-radius: 25px;
      font-weight: 400;
    }
    transition: color 0.2s linear;
    transition: background-color 0.2s linear;
  }

  &__category-statement {
    position: absolute;
    font-size: 2rem;
    top: -8.5rem;
    padding: 1rem 2rem 1rem 2rem;
    max-width: 90vw;

    border-radius: 25px;
    border: 1px solid rgba($color: #e71b69, $alpha: 0.8);
    background-color: rgba($color: #fff, $alpha: 0.6);
    @include device(tablet-land) {
      background-color: var(--primary-light);
      top: -6rem;
    }
  }
}
