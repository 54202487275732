*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 50%;
  @media (min-width: 37.5em) {
    font-size: 62.5%;
  }
}

body {
  box-sizing: border-box;
  font-family: var(--font-primary);
  color: var(--primary-grey);
  background-image: url('../assets/background/pink-circles.svg');
}

a {
  font-family: inherit;
  text-decoration: none;
  color: inherit;
}
