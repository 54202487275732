.h2 {
  color: var(--primary-grey);
  font-size: 4rem;
}

.h3 {
  color: var(--primary-grey);
  font-size: 1.8rem;
}

.highlight-pink {
  color: var(--primary-pink);
  font-weight: 700;
}

.highlight-purple {
  color: var(--primary-purple);
  font-weight: 700;
}

.highlight-orange {
  color: var(--primary-orange);
  font-weight: 700;
}

.highlight-green {
  color: var(--primary-green);
  font-weight: 700;
}
.highlight-blue {
  color: var(--primary-blue);
  font-weight: 700;
}
