.filter {
  &__container {
    display: flex;
    position: absolute;
    top: 11rem;
    z-index: 999;
  }

  &__select {
    font-size: 2rem;
    min-width: 50vw;
    padding: 1rem;
    border-radius: 15px;
    color: var(--primary-pink);
    background-color: #fff;

    @include device(tablet-port) {
      min-width: 400px;
      max-width: 400px;
    }

    &__option {
      border: none;
    }
  }
}
